<template>
  <vue-recaptcha
    sitekey="6LdAIE8UAAAAACmpiM6c2LZntPjsexgzC1-jywZd"
    :loadRecaptchaScript="true"
    @verify="validate"
    @expired="expiredMethod"
  >
  </vue-recaptcha>
</template>
 
<script>
// localhost: 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
// speedtest-test: '6LdAIE8UAAAAACmpiM6c2LZntPjsexgzC1-jywZd'
import VueRecaptcha from "vue-recaptcha";
import Validate from "@/service/verifyRecaptcha";

export default {
  components: { VueRecaptcha },
  methods: {
    async validate(response) {
      let result;
      try {
        result = await Validate.serverReCaptcha(response);

        if (result.status == 200) {
          this.$emit("validCaptcha", true);
        }
      } catch (err) {
        throw new Error(err);
      }
    },
    expiredMethod() {
      setTimeout(() => {
        this.$emit("validCaptcha", false);
      }, 4 * 60 * 1000);
    },
  },
};
</script>