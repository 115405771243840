<template>
  <div class="speedResult">
    <h2 class="headline">{{ headline }}</h2>
    <div class="result" :class="classBackground">{{ speed }} {{ unit }}</div>
  </div>
</template>
<script>
export default {
  name: "Speed",
  props: {
    headline: String,
    speed: Number,
    unit: String,
  },
  data: () => ({}),
  methods: {
    statusColor: function (statusCode) {
      let status = { [this.headline]: statusCode };
      this.$emit("updateStatusCode", status);
    },
  },
  mounted: function () {},

  computed: {
    classBackground: function () {
      switch (this.headline) {
        case "Download":
        case "Upload":
          if (this.speed == 0 || this.speed == null) {
            this.statusColor(4);
            return "mlp-grey";
          } else if (this.speed < 200) {
            this.statusColor(3);
            return "mlp-yellow";
          } else if (this.speed < 800) {
            this.statusColor(2);
            return "mlp-red";
          } else {
            this.statusColor(1);
            return "mlp-green";
          }
        case "Latenz":
          if (this.speed == 0 || this.speed == null) {
            this.statusColor(4);
            return "mlp-grey";
          } else if (this.speed > 80) {
            this.statusColor(3);
            return "mlp-yellow";
          } else if (this.speed > 100) {
            this.statusColor(2);
            return "mlp-red";
          } else {
            this.statusColor(1);
            return "mlp-green";
          }
        default:
          return "mlp-grey";
      }
    },
  },

  watch: {},
};
</script>
<style>
</style>