<template>
  <div class="textAddition">
    Dieser Netzwerktest prüft die Bandbreite und Güte der Netzwerkverbindung wie
    sie für den MLP Citrix Arbeitsplatz benötigt wird. Die absoluten Werte der
    Bandbreite können daher deutlich niedriger ausfallen als die insgesamt zur
    Verfügung stehende Bandbreite.
    <br />
    <br />
    Sie greifen über das Internet auf diese Seite zu ({{ ip }}). Der Test wurde
    am {{ date }} um {{ time }} Uhr durchgeführt.
  </div>
</template>
<script>
export default {
  name: "Speed",

  props: {
    ip: String,
    date: String,
    time: String,
  },
  data: () => ({}),
  methods: {},
  mounted: function () {},
  computed: {},
  watch: {
    selected: {
      handler() {},
    },
  },
};
</script>
<style>
</style>