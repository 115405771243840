<template>
  <v-app class="speedtest" xs>
    <noscript>
      <h2>Fehler</h2>
      Javascript ist deaktiviert. Der Test funktioniert nur mit aktiviertem
      Javascript.
    </noscript>
    <v-app-bar class="navigation-bar header" app>
      <v-row>
        <div align="center" justify="center" class="center-logo">
          <div class="mlp-logo">
            <v-img src="../public/mlplogo.png" class="mlp-logo" />
          </div>
          <div class="header-text">Netzwerkgüte</div>
        </div>
      </v-row>
    </v-app-bar>
    <div></div>
    <router-view></router-view>
    <Confirm ref="confirm"></Confirm>
  </v-app>
</template>

<script>
import Confirm from "@/components/Confirm.vue";
export default {
  name: "App",
  metaInfo: {
    title: "MLP - Speedtest",
    link: [
      { rel: "favicon", type: "image/png", href: "../public/favicon_mlp.png" },
    ],
  },
  components: { Confirm },
  mounted() {
    this.$root.$confirm = this.$refs.confirm;
  },
};
</script>
