import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import router from "./router/router";
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta'
import VueCookies from 'vue-cookies'

import "./assets/style.scss";

Vue.prototype.axios = axios;
Vue.config.productionTip = false;

Vue.use(VueMeta)
Vue.use(VueCookies)

Vue.$cookies.config('1d', '', '', true, 'None')

axios.defaults.withCredentials = true;

new Vue({
  render: (h) => h(App),
  axios,
  router,
  vuetify,
}).$mount("#app");

