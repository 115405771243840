import axios from 'axios'

export default {

    serverReCaptcha: async (token) => {
        let verifyResult
        try {
            verifyResult = await axios.post(process.env.VUE_APP_SPEEDTEST + ":" + process.env.VUE_APP_SPEEDTEST_PORT + "/verify", { token })
        } catch (err) {
            return err
        }
        return verifyResult
    }
}
