

<template>
  <div :class="classBackground">
    <div v-show="!this.captchaValid">
      <section class="section-container">
        <div class="entrycontent">
          <h2 class="headline">Willkommen zum MLP Speedtest.</h2>
          <div class="subtext">
            Um Missbrauch zu vermeiden, bestätigen Sie bitte, dass Sie kein
            Roboter sind.
          </div>
          <Recaptchav2 @validCaptcha="validCaptchaStatus" />
        </div>
      </section>
    </div>
    <div v-show="this.captchaValid">
      <v-dialog v-model="loading" persistent width="300">
        <v-card color="btt-yellow">
          <v-card-text>
            Test wird durchgeführt. Bitte warten ...
            <v-progress-linear
              indeterminate
              color="white"
              background-color="#96856A"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <div class="restart">
        <v-btn
          :disabled="this.loading"
          class="restart-btn"
          @click="restartTest"
          block
          large
          raised
          tile
        >
          Netzwerktest wiederholen
        </v-btn>
      </div>
      <div><Status headline="Endergebnis" :code="code" /></div>
      <div>
        <Speed
          headline="Download"
          :speed="downloadSpeed"
          unit="kbps"
          @updateStatusCode="statusCodeFromComponent"
        />
      </div>
      <div>
        <Speed
          headline="Upload"
          :speed="uploadSpeed"
          unit="kbps"
          @updateStatusCode="statusCodeFromComponent"
        />
      </div>
      <div>
        <Speed
          headline="Latenz"
          :speed="latencySpeed"
          unit="ms"
          @updateStatusCode="statusCodeFromComponent"
        />
      </div>
      <br />
      <div><TimeText :date="date" :time="time" :ip="ip" /></div>
    </div>
  </div>
</template>

<script>
import Speed from "../components/Speed.vue";
import TimeText from "../components/TimeText.vue";
import Status from "../components/Status";
import Recaptchav2 from "../components/Recaptchav2.vue";

import Download from "@/service/download";
import Upload from "@/service/upload";
import Latency from "@/service/latency";
import GetIP from "@/service/getIP";

import moment from "moment";
export default {
  components: {
    Speed,
    TimeText,
    Status,
    Recaptchav2,
  },
  props: {},
  data: () => ({
    downloadSpeed: 0,
    uploadSpeed: 0,
    latencySpeed: 0,
    statusCode: [],
    code: 0,
    citrixIP: false,
    date: moment().locale("de").format("L"),
    time: moment().locale("de").format("LTS"),
    ip: "",
    loading: false,
    captchaValid: false,
    captchaNotExpired: true,
  }),
  methods: {
    async startDownload() {
      this.downloadSpeed = await Download.download();
    },
    async startUpload() {
      this.uploadSpeed = await Upload.upload();
    },
    async startLatency() {
      this.latencySpeed = await Latency.latencyLoop();
    },
    async restartTest() {
      await this.whiteList();

      if (!this.captchaNotExpired) this.captchaValid = false;

      if (!this.citrixIP && this.captchaValid) {
        this.loading = true;
        await this.startDownload();
        await this.startUpload();
        await this.startLatency();
        this.date = moment().locale("de").format("L");
        this.time = moment().locale("de").format("LTS");
        this.setStatus();
        this.loading = false;
      }
    },
    statusCodeFromComponent(codeObj) {
      //TODO besser machen? moment.js
      if (!(Object.keys(codeObj)[0] in this.statusCode)) {
        this.statusCode[Object.keys(codeObj)[0]] = Object.values(codeObj)[0];
      } else {
        this.statusCode[Object.keys(codeObj)[0]] = Object.values(codeObj)[0];
      }
    },
    setStatus() {
      this.code = Math.max.apply(null, Object.values(this.statusCode));
    },
    whiteList() {
      let whiteList = [
        "52.149.108.216",
        "20.103.0.101",
        "52.155.162.74",
        "35.246.186.206",
        "34.79.209.253",
        // "127.0.0.1", //test localhost
      ];
      let cleanIP;
      if (this.ip) {
        cleanIP = this.ip.split(":")[0];
      }

      if (whiteList.includes(cleanIP)) {
        this.citrixIP = true;
        this.$root.$confirm.open(
          "Achtung",
          "Bitte starten Sie die Messung nicht innerhalb der Citrix-Sitzung, sondern direkt über den Browser Ihres Computers.",
          {
            color: "red",
          }
        );
      }
    },
    validCaptchaStatus(captchaStatus) {
      if (captchaStatus) {
        this.captchaValid = captchaStatus;
        this.captchaNotExpired = captchaStatus;
      } else {
        this.captchaNotExpired = captchaStatus;
      }
    },
  },
  async mounted() {},
  async created() {
    this.ip = await GetIP.getIP();
  },
  computed: {
    classBackground: function () {
      if (this.$vuetify.breakpoint.width < 600) {
        return "responsiveContainer";
      } else {
        return "fullContainer";
      }
    },
  },
  watch: {
    captchaValid: {
      immediate: true,
      async handler() {
        if (this.captchaValid) {
          await this.restartTest();
          this.setStatus();
        }
      },
    },
  },
};
</script>
