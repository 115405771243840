<template>
  <div class="speedResult">
    <h2 class="headline">{{ headline }}</h2>
    <div class="result" :class="classBackground">{{ statusText }}</div>
  </div>
</template>
<script>
export default {
  name: "Status",

  props: {
    code: Number,
    headline: String,
  },
  data: () => ({
    statusText: "123",
  }),
  methods: {
    setStatusText: function (text) {
      this.statusText = text;
    },
  },
  mounted: function () {},
  computed: {
    classBackground: function () {
      switch (this.code) {
        case 4:
          this.setStatusText(
            "Die Messung konnte auf ihrem Endgerät nicht erfolgreich abgeschlossen werden. Wiederholen sie den Test um Messfehler auszuschließen."
          );
          return "mlp-grey";
        case 3:
          this.setStatusText(
            "Ihre Netzwerkanbindung ist nicht für die Verwendung mit Citrix geeignet. Falls Sie über VPN verbunden sind, trennen Sie bitte die Verbindung. Wiederholen sie anschließend den Test um Messfehler auszuschließen."
          );
          return "mlp-red";
        case 2:
          this.setStatusText(
            "Ihre Netzwerkanbindung ist für die Verwendung mit Citrix geeignet. Es könnte jedoch zu Performance-Einschränkungen kommen. Falls Sie über VPN verbunden sind, trennen Sie bitte die Verbindung."
          );
          return "mlp-yellow";
        case 1:
          this.setStatusText(
            "Ihre Netzwerkanbindung ist für die Verwendung mit Citrix geeignet."
          );
          return "mlp-green";
        default:
          this.setStatusText(
            "Die Messung konnte auf ihrem Endgerät nicht erfolgreich abgeschlossen werden. Wiederholen sie den Test um Messfehler auszuschließen."
          );
          return "mlp-grey";
      }
    },
  },
  watch: {
    selected: {
      handler() {},
    },
  },
};
</script>
<style>
</style>