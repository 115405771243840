import axios from 'axios'
import hrtime from 'browser-process-hrtime'

let latency = {

    latency: async () => {
        let latencyTime

        try {
            const requestHash = Array.from(Array(64), () => Math.floor(Math.random() * 36).toString(36)).join('')
            const url = process.env.VUE_APP_SPEEDTEST + ":" + process.env.VUE_APP_SPEEDTEST_PORT + "/latency?" + requestHash
            const instance = axios.create()

            instance.interceptors.request.use((config) => {
                config.headers = { startTime: hrtime() }

                return config
            })

            instance.interceptors.response.use((response) => {
                const start = response.config.headers.startTime
                const end = hrtime(start)
                const milliseconds = Math.round((end[0] * 1000) + (end[1] / 1000000))
                response.headers = { endtime: milliseconds }

                return response
            })

            await instance.get(url).then((response) => {
                latencyTime = response.headers.endtime
            }).catch((err) => {
                console.log(err)
            })
        } catch (err) {
            return err
        }
        return latencyTime
    },
    latencyLoop: async () => {
        let latencySum = 0

        for (let i = 0; i < 10; i++) {
            let test = await latency.latency()
            latencySum += test
        }
        return Math.ceil(latencySum / 10)
    }
}

export default latency

/*Latenzberechnung durch 10-maliges Aufrufen einer leeren Datei(latency.html)oFormel: Summe aller Latenzen / Anzahl erfolgreicher Tests */
