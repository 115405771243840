import axios from 'axios'

export default {

    getIP: async function () {
        let getIPResult

        try {
            const requestHash = Array.from(Array(64), () => Math.floor(Math.random() * 36).toString(36)).join('')
            const url = process.env.VUE_APP_SPEEDTEST + ":" + process.env.VUE_APP_SPEEDTEST_PORT + "/ip?" + requestHash
            const instance = axios.create()

            await instance.get(url).then((response) => {

                getIPResult = response.data
            }).catch((err) => {
                console.log(err)
            })
        } catch (err) {
            return err
        }
        return getIPResult
    },

}

/*Download einer 20 MB großen Datei, Berechnung der benötigten ZeitoFormel: Dateigröße / vergangen Zeit*/