export default {
    kbits: async (size, time) => {

        let bit = size * 8
        let kiloBit = bit / 1000
        let kbits = kiloBit / time * 1000
        let roundKbits = Math.ceil(kbits)

        return roundKbits

    }
}