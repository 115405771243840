import Vue from "vue"
import VueRouter from "vue-router"
import Test from "@/views/Test.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: '/',
  },
  {
    path: "/",
    name: "Test",
    component: Test,
  },
]

const router = new VueRouter({
  routes,
  mode: "history"
})

export default router