import axios from 'axios'
import hrtime from 'browser-process-hrtime'
import calc from "@/service/calc";

export default {

    download: async () => {
        let downloadResultLength
        let downloadTime
        let downloadResult


        try {
            const requestHash = Array.from(Array(64), () => Math.floor(Math.random() * 36).toString(36)).join('')
            const url = process.env.VUE_APP_SPEEDTEST + ":" + process.env.VUE_APP_SPEEDTEST_PORT + "/download?" + requestHash
            const instance = axios.create()

            instance.interceptors.request.use((config) => {
                config.headers = { startTime: hrtime() }

                return config
            })

            instance.interceptors.response.use((response) => {
                const start = response.config.headers.startTime
                const end = hrtime(start)
                const milliseconds = Math.round((end[0] * 1000) + (end[1] / 1000000))
                response.headers = { endtime: milliseconds }

                return response
            })

            await instance.get(url).then((response) => {

                downloadTime = response.headers.endtime
                downloadResultLength = response.data.length

                //calc into 
                downloadResult = calc.kbits(downloadResultLength, downloadTime)

            }).catch((err) => {
                console.log(err)
            })
        } catch (err) {
            return err
        }
        return downloadResult
    }
}

/*Download einer 20 MB großen Datei, Berechnung der benötigten ZeitoFormel: Dateigröße / vergangen Zeit*/